.ant-input-number-affix-wrapper {
    div.ant-input-number {
        border: 1px solid #000000;
        border-right: none;
        border-left: none;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        &:focus,
        &:active,
        &:hover {
            border: 1px solid #40a9ff;
            border-left: none;
        }
    }
}
