@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter-Bold.woff2') format('truetype'),
        url('../assets/fonts/Inter-Bold.woff') format('woff'),
        url('../assets/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Inter-SemiBold.woff') format('woff'),
        url('../assets/fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter-Regular.woff2') format('woff2'),
        url('../assets/fonts/Inter-Regular.woff') format('woff'),
        url('../assets/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter-Medium.woff2') format('woff2'),
        url('../assets/fonts/Inter-Medium.woff') format('woff'),
        url('../assets/fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/Inter-ExtraBold.woff') format('woff'),
        url('../assets/fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

// *, body{
//     font-family: 'Inter', sans-serif;
// }