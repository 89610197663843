@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700&display=swap');

body{
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background: white;
  font-size: 100%;
}


.clickable{
  cursor: pointer;
}

html {
  --antd-wave-shadow-color: #9d9d9dcf;
}
