.ant-tabs-tab-btn {
  &:active,
  &:hover,
  &:focus {
    color: #a4a6a8;
  }
}

.ant-tabs-tab:hover {
  color: #a4a6a8;
}
